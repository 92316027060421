<template>
  <div class="good-item">
    <div style="">
      <div class="good-img">
        <a @click="openProduct(msg.proid)">
          <img v-lazy="msg.productImageBig  ||  baseURL + msg.mainImage" :alt="msg.name" :key="msg.mainImage">
        </a>
      </div>
      <!-- <h6 class="good-title" v-html="msg.name">{{msg.name}}</h6> -->
      <h3 class="sub-title ellipsis">{{msg.detail}}</h3>
      <div class="good-price pr">
        <div class="ds pa">
          <a @click="openProduct(msg.proid)">
            <y-button text="查看详情" style="margin: 0 5px"></y-button>
          </a>
          <y-button text="加入购物车"
                    style="margin: 0 5px"
                    @btnClick="addCart(msg.proid)"
                    classStyle="main-btn"
          ></y-button>
        </div>
        <p><span style="font-size:14px">￥</span>{{Number(msg.price).toFixed(2)}}</p>
      </div>
    </div>
  </div>
</template>
<script>
  import YButton from './YButton.vue'
  // import { addCart } from '/api/goods.js'
  import { XGShoppingAddCart, XGShoppingSelectCart } from '../server/api'
  import { mapMutations, mapState } from 'vuex'
  import store from "@/vuex/store";
  // import { getStore } from '/utils/storage'
  export default {
    props: {
      msg: {
        price: 0
      }
    },
    data () {
      return {}
    },
    methods: {
      ...mapMutations(['ADD_CART', 'ADD_ANIMATION', 'SHOW_CART']),
      goodsDetails (id) {
        this.$router.push({path: 'goodsDetails/proid=' + id})
      },
      openProduct (id) {
        this.$router.push({path: '/shopping/goodsDetails?proid=' + id})
        // window.open('//' + window.location.host + '/#/shopping/goodsDetails?proid=' + id)
      },
      // addCart (id, price, name, img) {
      //   if (!this.showMoveImg) {     // 动画是否在运动
      //     if (this.login) { // 登录了 直接存在用户名下
      //       addCart({userId: getStore('userId'), proid: id, productNum: 1}).then(res => {
      //         // 并不重新请求数据
      //         this.ADD_CART({proid: id, price: price, name: name, productImg: img})
      //       })
      //     } else { // 未登录 vuex
      //       this.ADD_CART({proid: id, price: price, name: name, productImg: img})
      //     }
      //     // 加入购物车动画
      //     var dom = event.target
      //     // 获取点击的坐标
      //     let elLeft = dom.getBoundingClientRect().left + (dom.offsetWidth / 2)
      //     let elTop = dom.getBoundingClientRect().top + (dom.offsetHeight / 2)
      //     // 需要触发
      //     this.ADD_ANIMATION({moveShow: true, elLeft: elLeft, elTop: elTop, img: img})
      //     if (!this.showCart) {
      //       this.SHOW_CART({showCart: true})
      //     }
      //   }
      // }
      addCart(id){
        if(!this.$store.state.login){
          this.$router.push({
            path: '/shopping/login'
          })
          return
        }
        const that = this
        console.log(this.$store.state.login);
            let param = {
              userId:null,
              proId: id,
              add: 1,
              cut:null,
              createTime:null
            }
            XGShoppingAddCart(param).then(res => {
              if(res.code === 200){
                  that.$message({
                  message: res.data,
                  type: 'success'
                })
                XGShoppingSelectCart().then((res) =>{
                  if(res.code === 200 && res.data){
                    let count = 0
                    res.data.forEach(item =>{
                      count+=item.proNumber
                    })
                    console.log("res.data.length", count);
                    store.commit("SET_SHOPPINGCARTCOUNT",count)
                  }
                })
              }
            })
      }
    },
    computed: {
      ...mapState(['login', 'showMoveImg', 'showCart'])
    },
    mounted () {
    },
    components: {
      YButton
    }
  }
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
  @import "../assets/style/mixin";
  @import "../assets/style/theme";

  .good-item {
    background: #fff;
    width: 25%;
    cursor: pointer;
    transition: all .5s;
    height: 430px;
    &:hover {
      transform: translateY(-3px);
      box-shadow: 1px 1px 20px #999;
      .good-price p {
        display: none;
      }
      .ds {
        display: flex;
      }
    }
    .ds {
      @extend %block-center;
      width: 100%;
      display: none;
    }

    .good-img {
      img {
        margin: 50px auto 10px;
        @include wh(206px);
        display: block;
      }
    }
    .good-price {
      margin: 15px 0;
      height: 30px;
      text-align: center;
      line-height: 30px;
      color: #d44d44;
      font-family: Arial;
      font-size: 18px;
      font-weight: 700;
    }
    .good-title {
      line-height: 1.2;
      font-size: 16px;
      color: #424242;
      margin: 0 auto;
      padding: 0 14px;
      text-align: center;
      overflow: hidden;
    }
    h3 {
      text-align: center;
      line-height: 1.2;
      font-size: 12px;
      color: #d0d0d0;
      padding: 10px;
    }

  }
</style>
